import React, { useState } from "react";
import "./style.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope } from "@fortawesome/free-regular-svg-icons";
import { faPhone } from "@fortawesome/free-solid-svg-icons";
import { faLocationDot } from "@fortawesome/free-solid-svg-icons";
import ReCAPTCHA from "react-google-recaptcha";

const Contact = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });

  const handleChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    fetch("/send-email", {
      // fetch('http://localhost:3001/send-email', {
      method: "POST",
      headers: {
        "Content-Type": "application/json",
      },
      body: JSON.stringify(formData),
    })
      .then((response) => response.text())
      .then((data) => {
        alert(data);
      })
      .catch((error) => {
        console.error("Error:", error);
      });
  };
  //contact send data

  const [captchaVerified, setCaptchaVerified] = useState(false);

  const handleCaptchaChange = (value) => {
    setCaptchaVerified(!!value);
  };

  const validateForm = (event) => {
    event.preventDefault();

    // Get form values
    const name = document.getElementById("name").value;
    const email = document.getElementById("email").value;
    const message = document.getElementById("message").value;
    const phone = document.getElementById("phone").value;
    const subject = document.getElementById("subject").value;
    const emailPattern = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    // Validation checks
    if (
      name.length >= 25 &&
      name.length <= 30 && // Name length condition
      emailPattern.test(email) && // Email pattern validation
      email.endsWith(".com") && // Email ending condition
      message && // Message not empty
      phone.match(/^\d+$/) && // Phone contains only digits
      captchaVerified // CAPTCHA must be verified
    ) {
      // Construct mailto link with the form data
      const mailtoLink = `mailto:support@khatatracker.com?subject=${encodeURIComponent(
        subject
      )}&body=Name: ${encodeURIComponent(
        name
      )}%0D%0AEmail: ${encodeURIComponent(
        email
      )}%0D%0APhone: ${encodeURIComponent(
        phone
      )}%0D%0AMessage: ${encodeURIComponent(message)}`;

      // Open mail client with pre-filled form data
      window.location.href = mailtoLink;

      // Show success message
      document.getElementById("successMessage").style.display = "block";
    } else {
      alert(
        "Please fill out all fields correctly. Name should be between 25 and 30 characters. Make sure the email ends with .com and the phone number contains only digits. Also, ensure the CAPTCHA is verified."
      );
    }
  };

  return (
    <div>
      <section id="contact_page">
        <div className="section-padding">
          <div className="container">
            <div className="row">
              <div className="col-xs-12">
                <div className="page-title text-center">
                  <br></br>
                  <h5 className="title">Contact Us</h5>
                  <div className="space-60"></div>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-xs-12 col-sm-4">
                <div className="footer-box">
                  <div className="box-icon">
                    <FontAwesomeIcon icon={faLocationDot} />
                  </div>
                  <p>Madhapur, Hyderabad, Telangana - 500081</p>
                </div>
                <div className="space-30 hidden visible-xs"></div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className="footer-box">
                  <div className="box-icon">
                    <FontAwesomeIcon icon={faPhone} />
                  </div>
                  <p>+91 7095044455</p>
                </div>
                <div className="space-30 hidden visible-xs"></div>
              </div>
              <div className="col-xs-12 col-sm-4">
                <div className="footer-box">
                  <div className="box-icon">
                    <FontAwesomeIcon icon={faEnvelope} />
                  </div>
                  <p>khatatracker@gmail.com</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-lg-12 col-md-12">
              {/* <form method="post" onSubmit={validateForm}> */}

              <form onSubmit={handleSubmit}>
                <div className="form-row">
                  <div className="row">
                    <div className="form-group col-xs-12 col-lg-6 col-md-6">
                      <label>Name</label>
                      <input
                        type="text"
                        className="form-control"
                        id="name"
                        name="name"
                        placeholder="Enter Your Name"
                        value={formData.name}
                        onChange={handleChange}
                        required
                      />
                    </div>
                    <div className="form-group col-xs-12 col-lg-6 col-md-6">
                      <label>Email</label>
                      <input
                        type="email"
                        className="form-control"
                        id="email"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        placeholder="Enter Your Email"
                        required
                      />
                    </div>
                  </div>
                  {/* <div className="form-group col-xs-12 col-lg-12 col-md-12">
                    <label>Subject</label>
                    <input
                      type="text"
                      className="form-control"
                      id="subject"
                      name="subject"
                      placeholder="Enter Subject"
                      required
                    />
                  </div> */}
                  <div className="form-group col-xs-12 col-lg-12 col-md-12">
                    <label>Message</label>
                    <textarea
                      className="form-control"
                      id="message"
                      name="message"
                      placeholder="Type Your Message Here..."
                      value={formData.message}
                      onChange={handleChange}
                      required
                    ></textarea>
                  </div>
                </div>

                <ReCAPTCHA
                  sitekey="6LcH1xUqAAAAAMb_59kdhJIbhb_APfSPx78DVX59"
                  onChange={handleCaptchaChange}
                />
                <center>
                  <div className="text-center span6">
                    <button
                      type="submit"
                      className="submit"
                      id="submit"
                      value="Submit"
                      style={{
                        marginTop: "10px",
                        marginBottom: "10px",
                        padding: "10px 10px",
                        backgroundColor: "#1AA260",
                        color: "#fff",
                        border: "none",
                      }}
                    >
                      Submit
                    </button>
                  </div>
                </center>
              </form>
              <div
                id="successMessage"
                style={{ display: "none", color: "green" }}
              >
                Form submitted successfully!
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
};

export default Contact;
