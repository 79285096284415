import React from 'react';

const CancellationRefundPolicy = () => {
  return (
    <div className='container' >
      <h3>Cancellation & Refund Policy</h3>
      <h4> <strong>Effective Date:</strong> [17/9/2024]</h4>
      <p>ANJU SIIMA TECHNOLOGIES PRIVATE LIMITED  is committed to assisting 
      its customers to the fullest extent possible and, as such, has adopted a flexible cancellation policy. Under this policy:</p>
     <ul>
     <li>Cancellations will be considered only if the request is made shortly after 
placing the order. However, cancellations may not be accepted if the order 
has already been processed or communicated to the relevant parties.</li>
<li>For paid services, your subscription will be valid for a specific duration. 
Renewal is required before the expiration of the license.</li>
<li>Khata Tracker does not automatically renew subscriptions. You will need to   
manually renew your subscription before expiration.</li>
<li>Refund requests must be submitted within 7 days of purchase. Requests 
submitted after this period may not be eligible for refunds.</li>
</ul>
</div>
  );
};

export default CancellationRefundPolicy;
