import React from 'react';
import './style.css'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faIndianRupeeSign } from '@fortawesome/free-solid-svg-icons';

const Overview = () => {
  return (
    <div>
      <section className="section-padding">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6">
              <div className="page-title">
                <h5 className="title wow fadeInUp" data-wow-delay="0.2s">App Over View</h5>
                <div className="space-10"></div>
                <ul className=" wow fadeInUp" data-wow-delay="0.4s"><li>Effortless expense logging with quick and easy input.</li>
                <li>Smart categorization of spending into customizable categories.</li>
                <li>Budget setting for different categories with helpful alerts.</li>
                <li>Insightful reports and visual charts to understand spending patterns.</li>
                <li>Timely reminders and notifications for bill payments.</li>
                <li>Multi-device synchronization for seamless data access.</li>
                <li>Robust security to protect financial data.</li>
                <li>User-friendly interface for a hassle-free experience.</li>
                </ul>
              
              <div className="space-20"></div>
              <div className="desc wow fadeInUp" data-wow-delay="0.6s">
                <p></p>
              </div>
              <div className="space-50"></div>
              {/* <a href="#" className="bttn-default wow fadeInUp" data-wow-delay="0.8s">Learn More</a> */}
            </div>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-5 col-md-offset-1">
              <div className="space-60 hidden visible-xs"></div>
              <div className="service-box wow fadeInUp" data-wow-delay="0.2s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faIndianRupeeSign} aria-hidden="true" />
                </div>
                <h4>Our Income</h4>
                <p>Enter Income what you get ie., Salary/Business & Other</p>
              </div>
              <div className="space-50"></div>
              <div className="service-box wow fadeInUp" data-wow-delay="0.2s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faIndianRupeeSign} aria-hidden="true" />
                </div>
                <h4>Spending Amount</h4>
                <p>For what Purpose you used that amount enter here ie., Eating/Entertainment/Food Etc.,</p>
              </div>
              <div className="space-50"></div>
              <div className="service-box wow fadeInUp" data-wow-delay="0.2s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faIndianRupeeSign} aria-hidden="true" />
                </div>
                <h4>Available Amount</h4>
                <p>Here it will Reduce your Spending amount with Income and displays the balance amount.</p>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}
export default Overview;
