import React, { useEffect, useState } from 'react';
import Img from '../assets/images/KT_Logo_white.png';
import '../components/nav.css';
import arrow from '../assets/images/left-arrow.gif';
const Navbar = () => {
  const [activeLink, setActiveLink] = useState('');
  useEffect(() => {
    const navbarHeight = document.querySelector('.mainmenu-area')?.offsetHeight || 0;
    const scrollToSection = (sectionId) => {
      const targetElement = document.getElementById(sectionId);
      if (targetElement) {
        window.scrollTo({
          top: targetElement.offsetTop - navbarHeight, // Adjust scroll position based on navbar height
          behavior: 'smooth',
        });
        setActiveLink(sectionId);
      }
    };
    // On initial load, check if the URL has a custom path (e.g., /home, /about-us)
    const pathName = window.location.pathname.substring(1); // Get the custom name without "/"
    const sectionMap = {
      home: 'home_page',
      'about-us': 'about_page',
      features: 'features_page',
      gallery: 'gallery_page',
      faq: 'faqs_page',
      'download-ios-android': 'download_page',
      'contact-us': 'contact_page',
    };
    if (sectionMap[pathName]) {
      scrollToSection(sectionMap[pathName], 
        
      );
    }

    // top: targetElement.offsetTop - navbarHeight,
    // const navbarHeight = document.querySelector('.mainmenu-area')?.offsetHeight || 0;

    const anchors = document.querySelectorAll('a[data-section]');
    const handleAnchorClick = (e) => {
      e.preventDefault();
      const targetId = e.currentTarget.getAttribute('data-section');
      scrollToSection(targetId);
      const customName = e.currentTarget.getAttribute('data-name');
      window.history.pushState(null, '', `/${customName}`); // Update the URL with the custom name
    };
    anchors.forEach((anchor) => {
      anchor.addEventListener('click', handleAnchorClick);
    });
    return () => {
      anchors.forEach((anchor) => {
        anchor.removeEventListener('click', handleAnchorClick);
      });
    };
  }, []);
  useEffect(() => {
    const sections = document.querySelectorAll('section');
    const handleScroll = () => {
      let currentSection = '';
      sections.forEach((section) => {
        const sectionTop = section.offsetTop;
        const sectionHeight = section.clientHeight;
        if (window.pageYOffset >= sectionTop - sectionHeight / 3) {
          currentSection = section.getAttribute('id');
        }
      });
      setActiveLink(currentSection);
    };
    window.addEventListener('scroll', handleScroll);
    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);
  return (
    <>
      <nav
        className="mainmenu-area navbar navbar-expand-lg navbar-light"
        data-spy="affix"
        data-offset-top="200"
      >
        <div className="container-fluid d-flex justify-content-between">
          <div className="d-flex align-items-center">
            <button
              type="button"
              className="navbar-toggler"
              data-toggle="collapse"
              data-target="#primary_menu"
              style={{ backgroundColor: '#fff' }}
            >
              <span className="navbar-toggler-icon"></span>
            </button>
            <a className="navbar-brand ml-2" href="index">
              <img src={Img} alt="Logo" className="nav_img" />
            </a>
          </div>
          <div className="collapse navbar-collapse justify-content-end" id="primary_menu">
            <ul className="navbar-nav ml-auto">
              <li className="nav-item">
                <a
                  className={`nav-link ${activeLink === 'home_page' ? 'active' : ''}`}
                  data-section="home_page"
                  data-name="home"
                  href="#home_page"
                >
                  Home
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeLink === 'about_page' ? 'active' : ''}`}
                  data-section="about_page"
                  data-name="about-us"
                  href="#about_page"
                >
                  About
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeLink === 'features_page' ? 'active' : ''}`}
                  data-section="features_page"
                  data-name="features"
                  href="#features_page"
                >
                  Features
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeLink === 'gallery_page' ? 'active' : ''}`}
                  data-section="gallery_page"
                  data-name="gallery"
                  href="#gallery_page"
                >
                  Gallery
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeLink === 'faqs_page' ? 'active' : ''}`}
                  data-section="faqs_page"
                  data-name="faq"
                  href="#faqs_page"
                >
                  FAQ's
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeLink === 'download_page' ? 'active' : ''}`}
                  data-section="download_page"
                  data-name="download-ios-android"
                  href="#download_page"
                >
                  Download
                </a>
              </li>
              <li className="nav-item">
                <a
                  className={`nav-link ${activeLink === 'contact_page' ? 'active' : ''}`}
                  data-section="contact_page"
                  data-name="contact-us"
                  href="#contact_page"
                >
                  Contact
                </a>
              </li>
              <li className="nav-item">
                <button
                  className={`nav-link ${activeLink === 'login' ? 'active' : ''} login-button`}
                  target="_blank"
                  onClick={() => window.open('https://khatatracker.com/business/', '_blank')}
                >
                  &nbsp; Login
                  <img src={arrow} alt="gif" className="left-arrow" />
                </button>
              </li>
            </ul>
          </div>
        </div>
      </nav>
    </>
  );
};
export default Navbar;