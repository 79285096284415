 import React from 'react';
import './style.css';
import Feature from '../assets/images/feature-image (1).png';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faRocket, faPaperclip, faCloudDownload, faClock, faLaptop, faCog } from '@fortawesome/free-solid-svg-icons';

const Features = () => {
  return (
    <div>
      <section className="feature-area section-padding-top" id="features_page">
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-8 col-sm-offset-2">
            <div className="page-title text-center margin" >
  <h5 className="title">Features</h5>
  <div className="space-10"></div>
  <h3>Powerful Features As Always</h3>
  <div className="space-60"></div>
</div>

              <br></br>

            </div>
          </div>
          <div className="row">
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="service-box wow fadeInUp" data-wow-delay="0.2s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faRocket} />
                </div>
                <h4>Fast &amp; Powerful</h4>
                <p>Fast Download & Low Data Consumption</p>
              </div>
              <br></br>
              <br></br>

              <div className="space-60"></div>
              <div className="service-box wow fadeInUp" data-wow-delay="0.4s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faPaperclip} />
                </div>
                <h4>Easily Editable</h4>
                <p>Easy to edit the data or mistakes</p>
              </div>
              <br></br>
              <br></br>

              <div className="space-60"></div>
              <div className="service-box wow fadeInUp" data-wow-delay="0.6s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faCloudDownload} />
                </div>
                <h4>Cloud Storage</h4>
                <p>Data storage is secure</p>
              </div>
              <div className="space-60"></div>
            </div>
            <div className="hidden-xs hidden-sm col-md-4">
              <figure className="mobile-image">
                <img src={Feature} alt="Feature Photo" />
              </figure>
            </div>
            <div className="col-xs-12 col-sm-6 col-md-4">
              <div className="service-box wow fadeInUp" data-wow-delay="0.2s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faClock} />
                </div>
                <h4>Easy Notifications</h4>
                <p>To make your search easier we've put together a handy list of the best push notification services for your business.</p>
              </div>
              <br></br>

              <div className="space-60"></div>
              <div className="service-box wow fadeInUp" data-wow-delay="0.4s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faLaptop} />
                </div>
                <h4>Fully Responsive</h4>
                <p>Rescale itself to Preserve the User Experience and look and feel across all devices</p>
              </div>
              <div className="space-60"></div>
              <div className="service-box wow fadeInUp" data-wow-delay="0.6s">
                <div className="box-icon">
                  <FontAwesomeIcon icon={faCog} />
                </div>
                <br></br>

                <h4>Ratings and reviews</h4>
                <p>High Rating and Views</p>
              </div>
              <div className="space-60"></div>
            </div>
          </div>
        </div>
      </section>
    </div>
  );
}

export default Features;



