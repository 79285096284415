// StandaloneManageAccount.js
import React from 'react';
import DeleteAccountInstructions from './manage';

const StandaloneManageAccount = () => {
  return (
    <div>
      <DeleteAccountInstructions />
    </div>
  );
};

export default StandaloneManageAccount;
