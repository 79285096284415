import React, { useState, useEffect } from 'react';
import './home.css';
import img from '../assets/images/destop.png';
import playstore from '../assets/images/PlayStoreBadge.png';
import google from '../assets/images/GooglePlayStore.png';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { useLocation, useNavigate } from 'react-router-dom';
import Popup from './popup';
import axios from 'axios';
import OTPVerification from '../pages/Verify_OTP/verifyotp';

const Home = () => {

  const navigate = useNavigate();
  const [formData, setFormData] = useState({
    email: '',
    displayName: '',
    password: '',
    mobilenumber: '',

  });
  const [error, setError] = useState('');
  const [weberror, setWeberror] = useState('');
  const [showPassword, setShowPassword] = useState(false);
  const [re_showPassword, setRe_showPassword] = useState(false);
  const [passwordValue, setPasswordValue] = useState('');
  const [re_passwordValue, setRe_PasswordValue] = useState('');

  const generateSessionId = () => {
    return 'session_' + Math.random().toString(36).substr(2, 9);
  };

  const handleChange = (e) => {
    setError('');
    const { name, value } = e.target;
    if (name === 'mobilenumber') {
      if (!(/^\d*$/.test(value))) {
        return;
      }
    }
    if (name == 'password') {
      setPasswordValue(value)
    }
    if (name == 're_password') {
      setRe_PasswordValue(value);

    }
    setFormData({
      ...formData,
      [name]: value,
    });

  };

  const handleClosePopup = () => {
    setShowPopup(false);
  };

  const [show, setShow] = useState(false);
  const [showOtpModal, setShowOtpModal] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const handleOtpClose = () => setShowOtpModal(false);
  const handleOtpShow = () => {
    setShowOtpModal(true);
    setShow(false)
  }

  const [showPopup, setShowPopup] = useState(false);
  const [showOTPPopup, setShowOTPPopup] = useState(false);
  const [showRegisterPopup, setShowRegisterPopup] = useState(true);
  const handleCloseOTPPopup = () => {
    setShowOTPPopup(false);
  };

  const handleSubmit = async (e) => {

  

    e.preventDefault();

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;
    if (!emailRegex.test(formData.email)) {
      setError('Invalid email format');
      return;
    }

    if (!(re_passwordValue == passwordValue)) {
      setError('Password not matched.')
      return;
    }

    try {
      const urlEncodedData = new URLSearchParams();
      urlEncodedData.append('email', formData.email);
      urlEncodedData.append('mobilenumber', formData.mobilenumber);

      const response = await fetch('http://api.khatatracker.com:3000/otpsend', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded'
        },
        body: urlEncodedData.toString()
      });
      const userdata = await response.json();
      console.log(userdata, 'userdata')
      setWeberror(userdata.message)

      if (userdata.status === true) {
        const sessionId = generateSessionId();
        sessionStorage.setItem('sessionId', sessionId);
        sessionStorage.setItem(`verifyEmail_${sessionId}`, formData.email);
        sessionStorage.setItem(`OTP${sessionId}`, userdata.OTP);
        sessionStorage.setItem('formData', JSON.stringify(formData));
        // console.log(userdata, 'userdata')
        console.log(formData.email, 'formData.email')

        // navigate('/verifyotp');
        setShowRegisterPopup(false);
        setShowOTPPopup(true);
        handleOtpShow();
      } else if (response.data.status == 'webloginfail') {
        setError(response.data.message);
        return;
      } else {
        setError(userdata.message);
      }
    } catch (error) {
      console.error('Error:', error);
      setError('An error occurred. Please try again.');
    }
  };

  const location = useLocation();
  const [otp, setOTP] = useState(''); 
  const sessionId = sessionStorage.getItem('sessionId');
  const storedEmail = sessionStorage.getItem(`verifyEmail_${sessionId}`);
  const formDataval = JSON.parse(sessionStorage.getItem('formData'));
  const [email, setEmail] = useState(storedEmail || '');

const modalstyles = {
    marginTop: '0px',
    zIndex: 1000000,
    padding: '0px',
    
}
  return (
    <>
      <section className="khata-tracker container-fluid " id="home_page">
        <div className="row align-items-center justify-content-between">
          <div className="col-lg-5 col-md-6 col-sm-12 text-section container" style={{ fontFamily: "Nunito" }}>
            <h1 className="display-4 fw-bold mt-5">
              Simplify Your<br /> Business Accounts <br />with<span style={{ color: "#99FFEA" }}> Khata Tracker!</span>
            </h1>
            <p style={{ textAlign: 'justify' }}>
              Khata Tracker helps you manage your finances. It's a simple app for managing cash, perfect for any type of business.
              With Khata Tracker, you can track daily income, expenses, sales, purchases, and all your credit and debit entries.
              Managing your day-to-day transactions is quick and effortless.
            </p>
            <div className="buttons my-4">
              <button onClick={handleShow} className='trail'> Start 7 Day Free Trial </button>

              <Modal show={show} onHide={handleClose} style={modalstyles}>
                <div style={{border:'3px solid rgb(8, 99, 81)',borderRadius:'6px'}}>
                <Modal.Header closeButton className="custom-modal-close" style={{ background: 'rgb(8, 99, 81)', color: '#FFFFFF' ,borderRadius:'0px'}}>
                  <Modal.Title>Register</Modal.Title>
                </Modal.Header>

                <Modal.Body style={{ background: '#EFFFFA' ,border:'1px solid rgb(8, 99, 81)'}} >
                  <Form onSubmit={handleSubmit}>

                    <Form.Group>
                      <Form.Label>Name</Form.Label>
                      <Form.Control
                        id="name"
                        placeholder="Enter your name"
                        type="text"
                        name="displayName"
                        value={formData.displayName}
                        onChange={handleChange}
                        autoComplete="off"
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Email</Form.Label>
                      <Form.Control
                        id="email"
                        placeholder="Enter your email"
                        type="text"
                        name="email"
                        value={formData.email}
                        onChange={handleChange}
                        autoComplete="off"
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Mobile</Form.Label>
                      <Form.Control
                        id="mobile"
                        type="text"
                        name="mobilenumber"
                        placeholder="Enter mobile number"
                        value={formData.mobilenumber}
                        onChange={handleChange}
                        autoComplete="off"
                        minLength={10}
                        maxLength={10}
                        required
                      />
                    </Form.Group>

                    <Form.Group>
                      <Form.Label>Password</Form.Label>
                      <Form.Control
                        id="password"
                        placeholder="Enter password"
                        type={showPassword ? 'text' : 'password'}
                        name="password"
                        value={formData.password}
                        onChange={handleChange}
                        autoComplete="off"
                        required
                      />
                      {/* <div onClick={togglePasswordVisibility} className="eye-icons">
                {showPassword ? <FaEye /> : <FaEyeSlash />}
              </div> */}
                    </Form.Group>

                    <Form.Group className='mb-2'>
                      <Form.Label>Re-Enter Password</Form.Label>
                      <Form.Control
                        id="re_password"
                        type={re_showPassword ? 'text' : 'password'}
                        name="re_password"
                        placeholder="Re-enter password"
                        value={formData.re_password}
                        onChange={handleChange}
                        autoComplete="off"
                        required
                      />
                    </Form.Group>
 
                    <br />
                    <div className="d-flex justify-content-center mt-1">
 
                      <Button type="submit" style={{ background: '#086351' }}>
                        Submit
                      </Button>
                    </div>
                    {/* <div>{weberror}</div> */}
                    {error && <div style={{ color: 'red' ,textAlign:"center"}}>{weberror}</div>}
                  </Form>
                </Modal.Body>
                </div>

              </Modal>

              <Modal show={showOtpModal} onHide={handleOtpClose} backdrop="static">
                <Modal.Header closeButton className="custom-modal-close" style={{ background: 'rgb(8, 99, 81)', color: '#FFFFFF' }}>
                  <Modal.Title>Verification Code</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <p style={{fontSize:'0.85em',textAlign:'center',color:'#086351'}}>Verification Code sent to your email: {formData.email}</p>
                  <OTPVerification />
                </Modal.Body>
              </Modal>

              <button
                className="trails"
                onClick={() => window.open('https://khatatracker.com/business/register', '_blank')}
              >
                Register Now
              </button>
            </div>
            <p>Paid plans starting from <span style={{ color: '#2DF9D0', textDecoration: 'underline' }}>Rs. 699/month</span></p>
            <div className="play-store mt-4">
              <p style={{ color: "#fff" }}>Also available here:</p>
              <div className="d-flex">
                <a href="https://apps.apple.com/in/app/khata-book-spending-tracker/id1517793704" target='_blank' rel="noopener noreferrer">
                  <img src={playstore} alt="App Store" className="me-2" />
                </a> &nbsp;&nbsp;
                <a href='https://play.google.com/store/apps/details?id=personal.expense.budget.pocket.moneytracker&pcampaignid=web_share' target='_blank' rel="noopener noreferrer">
                  <img src={google} alt="Play Store" />
                </a>
              </div>
            </div><br />
          </div>
          <div className="col-lg-6 col-md-6 col-sm-12 image-section p-0">
            <div className="device-images d-flex justify-content-center">
              <img src={img} alt="Desktop View" className="img-fluid desktop-img" />
            </div>
          </div>
        </div>
        <Popup />
      </section>
      <style jsx>{`
        .modal { 
          justify-content: center;
          align-items: center; 
          z-index: 100000; 
          width: 100%; 
          background-color: rgba(0, 0, 0, 0.5);
        }
        .bgColor{
          background-color: #DDD5CC; 
        } 
        button {
          padding: 10px 20px; 
          border: none;
          border-radius: 4px;
          cursor: pointer;
        }
         
      `}</style>

    </>
  );
};

export default Home;