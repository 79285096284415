import React, { useState, useEffect } from 'react';
import '../src/SMS.css';
import BANNER from '../src/assets/images/banner.png';
import logo from '../src/assets/images/KT_Logo.png'

function SMS_View() {
  const BASE_URL = `https://api.khatatracker.com`;
  const [requestData, setRequestData] = useState(null);
  const [error, setError] = useState(null);
  const [showcard, setShowcard] = useState(false);
  useEffect(() => {
    const hash = window.location.hash.substring(1);
    const apiUrl = `${BASE_URL}/getCustomerByRandom/${hash}`;
   

    const fetchData = async (url) => {
      try {
        const response = await fetch(url);
        const data = await response.json();
        console.log(data);
        if (data.status === true) {
         
          setRequestData(data);
          setShowcard(true);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };


    if (hash) {
      fetchData(apiUrl);
    } else {
      console.error('No hash found in the URL');
      setError('No hash found in the URL');
    }
  }, [BASE_URL]);
  const handleStoreRedirect = () => {

    const userAgent = navigator.userAgent || navigator.vendor || window.opera;

    // Check if the user agent is iOS
    if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
      window.location.href = "https://apps.apple.com/us/app/khata-book-my-spending-tracker/id1517793704"; // Replace with your App Store link
    } else if (/android/i.test(userAgent)) { // Check if the user agent is Android
      window.location.href = "https://play.google.com/store/apps/details?id=personal.expense.budget.pocket.moneytracker"; // Replace with your Play Store link
    }

  };
  const getInitials = (customerName) => {
    console.log(customerName);
    const nameParts = customerName.split(' ');
    const firstNameInitial = nameParts[0]?.charAt(0).toUpperCase() || '';
    const lastNameInitial = nameParts[1]?.charAt(0).toUpperCase() || '';
    return `${firstNameInitial}${lastNameInitial}`;
  };
  const formatDateString = (dateString) => {
    const date = new Date(dateString);
    const daysOfWeek = ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'];
    const dayOfWeek = daysOfWeek[date.getDay()];
    const day = String(date.getDate()).padStart(2, '0');
    const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
    const year = date.getFullYear();
    const hours = String(date.getHours()).padStart(2, '0');
    const minutes = String(date.getMinutes()).padStart(2, '0');
    const seconds = String(date.getSeconds()).padStart(2, '0');

    return `${dayOfWeek} ${day}-${month}-${year} ${hours}:${minutes}:${seconds}`;
  };
  const formatDate = (date) => {
    const options = { day: '2-digit', month: 'short', year: 'numeric' };
    return new Date(date).toLocaleDateString('en-GB', options);
  };


  const startCall = () => {
    window.open('tel:7095044455', '_blank');
  }

  const usercall = (num) => {
    window.open(`tel:${num}`, '_blank');
  }
  return (
    <div>
      {showcard && requestData && requestData.data && (
        <div className="transaction-history">
          <div className="header">
            <div className="left-section">
              <div
                className="rounded-circle d-flex justify-content-center align-items-center initials-circle username"
              >
                {getInitials(requestData.data.name)}
              </div>&nbsp;&nbsp;

              <span style={{ color: 'white', fontSize: '1.2em' ,marginTop:"10px"}} >
                {requestData.data.name.charAt(0).toUpperCase() + requestData.data.name.slice(1).toLowerCase()}
              </span>


            </div>
            <div className='makecall'>
              <span className='phimg' onClick={() => usercall(requestData.senderNumber)}> <svg style={{ color: 'white' }} xmlns="http://www.w3.org/2000/svg" fill="currentColor" className="bi bi-telephone-fill" viewBox="0 0 16 16">
                <path fill-rule="evenodd" d="M1.885.511a1.745 1.745 0 0 1 2.61.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
              </svg>&nbsp;<span className='call-text'>Make A Call</span></span>
            </div>
            <div className="right-section">
              <img className="profile-pic" src={logo} alt="Profile" />

            </div>
          </div>

          <div className="ad-banner">
            <img src={BANNER} alt="Ad Banner" onClick={handleStoreRedirect} />
          </div>

          <div className="transaction-summary">
            <p className='table_title' style={{ fontSize: '150%' }}><b>Transaction History</b></p>
            <p>({formatDate(requestData.fromDate)} - {formatDate(requestData.toDate)})</p>
            <div className="summary-details">
              
              <div >
                <b>Total Credit(+):</b>
                <span style={{ color: '#4caf50' }}>{requestData.income}</span>
              </div>
              <div>
                <b>Total Debit(-):</b>
                <span style={{ color: '#d50000' }}>{requestData.expense}</span>
              </div>
              <div>
                <b>Net Balance:</b>
                <span style={{ color: requestData.balance < 0 ? "#b71c1c" : "#81c784" }}>
                  {requestData.balance}
                </span>
              </div>
            </div>
          </div>


          <table className="transaction-table">
            <thead>
              <tr>
                <th>Date</th>
                <th>Credit (+)</th>
                <th>Debit (-)</th>
                <th>Balance</th>
              </tr>
            </thead>
            <tbody>
              {requestData.data.customer.map((value, index) =>
                <tr key={(index)}>
                  <td>{formatDate(value.date)}</td>
                 
                  {value.type == 'income' ? (
                    <td style={{ backgroundColor: "#e8f5e9" }}>{value.amount}</td>
                  ) : (
                    <td style={{ backgroundColor: "#e8f5e9" }}>0</td>
                  )}
                   {value.type == 'expense' ? (
                    <td style={{ backgroundColor: "#ffebee" }}>{value.amount}</td>
                  ) : (
                    <td style={{ backgroundColor: "#ffebee" }}>0</td>
                  )}
                  <td style={value.balance < 0 ? { color: "#b71c1c" } : { color: "#81c784" }}>{value.balance}</td>
                </tr>
              )}

            </tbody>
          </table>
          <div className="footer">
            <div>khatatracker@gmail.com</div>
            <div>
              <span onClick={startCall} className='support'>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  fill="currentColor"
                  className="bi bi-telephone"
                  viewBox="0 0 16 16"
                  style={{ marginTop: "-5px" }}
                >
                  <path d="M3.654 1.328a.678.678 0 0 0-1.015-.063L1.605 2.3c-.483.484-.661 1.169-.45 1.77a17.6 17.6 0 0 0 4.168 6.608 17.6 17.6 0 0 0 6.608 4.168c.601.211 1.286.033 1.77-.45l1.034-1.034a.678.678 0 0 0-.063-1.015l-2.307-1.794a.68.68 0 0 0-.58-.122l-2.19.547a1.75 1.75 0 0 1-1.657-.459L5.482 8.062a1.75 1.75 0 0 1-.46-1.657l.548-2.19a.68.68 0 0 0-.122-.58zM1.884.511a1.745 1.745 0 0 1 2.612.163L6.29 2.98c.329.423.445.974.315 1.494l-.547 2.19a.68.68 0 0 0 .178.643l2.457 2.457a.68.68 0 0 0 .644.178l2.189-.547a1.75 1.75 0 0 1 1.494.315l2.306 1.794c.829.645.905 1.87.163 2.611l-1.034 1.034c-.74.74-1.846 1.065-2.877.702a18.6 18.6 0 0 1-7.01-4.42 18.6 18.6 0 0 1-4.42-7.009c-.362-1.03-.037-2.137.703-2.877z" />
                </svg>
                &nbsp;
                Call Support
              </span>
            </div>
          </div>

        </div>
      )}

    </div>

  );
}

export default SMS_View;
