// import React, { useState, useRef, useEffect } from 'react';
// import { useNavigate, useLocation, Link } from 'react-router-dom';
// import Modals from 'react-modal';
// import axios from 'axios';
// const OTPVerification = () => {
//   const navigate = useNavigate();
//   const location = useLocation();
//   const [otp, setOTP] = useState(['', '', '', '', '', '']);
//   const inputRefs = useRef([]);
//   const [error, setError] = useState('');
//   const [showModal, setShowModal] = useState(false);
//   const searchParams = new URLSearchParams(window.location.search);
//   const propValue = searchParams.get('Update');
//   let sessionId = sessionStorage.getItem('sessionId');
//   const storedEmail = sessionStorage.getItem(`verifyEmail_${sessionId}`);
//   const formDataval = JSON.parse(sessionStorage.getItem('formData'));
//   const [email, setEmail] = useState(storedEmail || '');

//   useEffect(() => {
//     if (!storedEmail && location.state) {
//       setEmail(location.state.email);
//     }
//   }, [storedEmail, location.state]);
//   const [update_psw, setUpdate_psw] = useState(false);
//   useEffect(() => {
//     const queryParams = new URLSearchParams(location.search);
//     const updateval = queryParams.get('Update');

//     if (updateval) {

//       sessionStorage.setItem('updatepsw', 'Updated_password');

//     }
//   }, [location.search]);
//   useEffect(() => {
//     console.log('update_psw state:', update_psw);
//   }, [update_psw]);
//   const handleInputChange = (index, value) => {
//     setError('');
//     const newOTP = [...otp];
//     newOTP[index] = value;
//     setOTP(newOTP);
//     if (value !== '' && index < inputRefs.current.length - 1) {
//       inputRefs.current[index + 1].focus();
//     }
//   };

//   const handleResend = async () => {
//     try {
//       setError('');
//       const checkval = sessionStorage.getItem('updatepsw');


//       const urlEncodedData = new URLSearchParams();
//       urlEncodedData.append('email', email);


//       const response = await fetch('http://api.khatatracker.com:3000/otpsend', {
//         method: 'POST',
//         headers: {
//           'Content-Type': 'application/x-www-form-urlencoded'
//         },
//         body: urlEncodedData.toString()
//       });
//       const userdata = await response.json();


//       if (response.status !== 200) {
//         throw new Error('Failed to resend OTP. Server responded with an error.');
//       }

//       if (userdata.status === true) {
//         setOTP(['', '', '', '', '', '']);
//         console.log('OTP resent successfully');
//         sessionStorage.setItem(`OTP${sessionId}`, userdata.OTP);
//         setError('New OTP is sent to your email.');
//       } else {
//         console.log('OTP resend failed');
//         setError('OTP resend failed. Please try again.');
//       }
//     } catch (error) {
//       console.error('Error occurred:', error);
//       setError('Failed to resend OTP. Please try again.');
//     }
//   };
//   const [regi_succes, setRegi_succes] = useState(false);
//   const [Trailperiod, setTrailperiod] = useState('');

//   const handleVerify = async (event) => {
//     const checkval = sessionStorage.getItem('updatepsw');

//     event.preventDefault();

//     const enteredOTP = otp.join('');

//     const otpverify = sessionStorage.getItem(`OTP${sessionId}`);

//     try {
//         if (otpverify == enteredOTP) {
//           const response = await axios.post('http://18.61.175.229:3000/register', formDataval);
//           console.log('Response:', response.data);
//           if (response.data.status === true) {
//             navigate('/login');
//           }

//         } else {
//           setError('Please provide valid OTP');
//           return;
//         }
//     } catch (error) {
//       console.error('Error occurred:', error);
//       setError('An error occurred. Please try again.');
//     }
//   };
//   useEffect(() => {
//     console.log('update_psw state:', showModal);
//   }, [showModal]);
//   const handleBackspace = (index, e) => {
//     if (e.key === 'Backspace' && index > 0 && !otp[index]) {
//       inputRefs.current[index - 1].focus();
//     }
//   };
//   const handleUpdatepsw = () => {
//     setShowModal(false);
//     const params = new URLSearchParams();
//     params.append('Update', 'Update_PSW');
//     window.location.href = '/business/forgotpassword?' + params.toString();
//   }
//   const handleCloseModal = () => {
//     setShowModal(false);
//     setRegi_succes(false);
//     sessionStorage.removeItem('updatepsw');
//     navigate('/business/login');
//   };

//   const containerStyle = {
//     display: 'flex',
//     marginLeft: '1%',
//     gap: '18px',
//   };

//   const inputStyle = {
//     textAlign: 'center',
//     width: '40px',
//     height: '40px',
//     fontSize: '20px',
//     margin: '5px',
//     border: '1px solid #ccc',
//     borderRadius: '5px',
//   };
//   const customStyles = {
//     overlay: {
//       position: 'fixed',
//       top: 0,
//       left: 0,
//       right: 0,
//       bottom: 0,
//       backgroundColor: 'rgba(0, 0, 0, 0.75)', // Semi-transparent background
//       display: 'flex',
//       justifyContent: 'center',
//       alignItems: 'center',
//       zIndex: 1000, // Ensure it's above other elements
//     },
//     content: {
//       position: 'relative',
//       background: 'white',
//       borderRadius: '10px',
//       padding: '20px',
//       width: '400px', // Adjust width as needed
//       boxShadow: '0 5px 15px rgba(0, 0, 0, 0.3)',
//       zIndex: 1001, // Ensure it's above overlay
//       top: 'auto',
//       left: 'auto',
//       right: 'auto',
//       bottom: 'auto',
//       marginRight: '-30%',
//       marginTop: '15%',
//       transform: 'translate(-50%, -50%)',
//     },
//   };
//   return (
//     <>
//       <div className="login-container">
//         <div className="login-box">
//           <div className="login-title">
//           </div>
//           <form className='login-form'>
//             <div className="form-group ">
//               <p style={{ whiteSpace: 'nowrap', textAlign: 'center' }}>OTP sent to your email: <br />{email}</p>
//               <label htmlFor="email"></label>
//               <div style={containerStyle}>
//                 {otp.map((digit, index) => (
//                   <input
//                     className="form"
//                     key={index}
//                     type="text"
//                     maxLength="1"
//                     value={digit}
//                     onChange={(e) => handleInputChange(index, e.target.value)}
//                     onKeyDown={(e) => handleBackspace(index, e)}
//                     ref={(input) => (inputRefs.current[index] = input)}
//                     style={inputStyle}
//                   />
//                 ))}
//               </div>
//             </div>
//             <button type="submit" className="btn btn-login btn-block" onClick={handleVerify}>Verify OTP</button>
//             {error && <p style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>{error}</p>}
//             <div className="login-footer">
//               <p>Don't have an account yet? &nbsp;<Link onClick={handleResend} style={{ color: 'black' }}>Resend OTP</Link></p>
//             </div>
//           </form>
//         </div>
//       </div>
//       <Modals
//         isOpen={showModal}
//         onRequestClose={() => setShowModal(false)}
//         className="modal-content"
//         overlayClassName="modal-overlay"
//         contentLabel="Trial Period"
//         shouldCloseOnOverlayClick={false}
//         style={customStyles}
//       >
//         <p closeButton></p>
//         <h3 className="modal-title">OTP Verified</h3>
//         <p className="modal-message">
//           Your OTP has been successfully verified.
//         </p>
//         <div className="modal-button-container">
//           <button onClick={handleUpdatepsw}>OK</button>
//         </div>
//       </Modals>


//       <Modals
//         isOpen={regi_succes}
//         onRequestClose={() => setRegi_succes(false)}
//         className="modal-content"
//         overlayClassName="modal-overlay"
//         contentLabel="Trial Period"
//         shouldCloseOnOverlayClick={false}
//         style={customStyles}
//       >
//         <p closeButton></p>
//         <h3 className="modal-title">OTP Verified</h3>
//         <p className="modal-message">
//           Your Registration is successful.
//           Your {Trailperiod} Days Free Trail Period is started.
//         </p>
//         <div className="modal-button-container">
//           <button onClick={handleCloseModal}>OK</button>
//         </div>
//       </Modals>

//     </>
//   );
// };

// export default OTPVerification;



import React, { useState, useEffect } from 'react';
import { useNavigate, useLocation } from 'react-router-dom';
import axios from 'axios';
import { Button, Form } from 'react-bootstrap';





const OTPVerification = () => {
  const navigate = useNavigate();
  const location = useLocation();
  const [otp, setOTP] = useState(''); // Single input for OTP
  const [error, setError] = useState('');
  const sessionId = sessionStorage.getItem('sessionId');
  const storedEmail = sessionStorage.getItem(`verifyEmail_${sessionId}`);
  const formDataval = JSON.parse(sessionStorage.getItem('formData'));
  const [email, setEmail] = useState(storedEmail || '');



  useEffect(() => {
    // Create the script element
    const script = document.createElement('script');
    script.src = 'https://www.googletagmanager.com/gtag/js?id=AW-787559108';
    script.async = true;
    document.body.appendChild(script);

    // Initialize dataLayer and gtag
    window.dataLayer = window.dataLayer || [];
    window.gtag = function() {
      window.dataLayer.push(arguments); // Reference dataLayer through window
    };
    window.gtag('js', new Date());
    window.gtag('config', 'AW-787559108');

    // Cleanup on unmount
    return () => {
      document.body.removeChild(script);
    };
  }, []);

  useEffect(() => {
    if (!storedEmail && location.state) {
      setEmail(location.state.email);
    }
  }, [storedEmail, location.state]);

  const handleInputChange = (value) => {
    setError('');
    setOTP(value); // Update OTP value on input change
  };

  const handleResend = async () => {
    try {
      setError('');
      const urlEncodedData = new URLSearchParams();
      urlEncodedData.append('email', email);

      const response = await fetch('http://api.khatatracker.com:3000/otpsend', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/x-www-form-urlencoded',
        },
        body: urlEncodedData.toString(),
      });
      const userdata = await response.json();

      if (response.status !== 200) {
        throw new Error('Failed to resend OTP. Server responded with an error.');
      }

      if (userdata.status === true) {
        setOTP(''); // Reset OTP input
        sessionStorage.setItem(`OTP${sessionId}`, userdata.OTP);
        setError('New OTP is sent to your email.');
      } else {
        setError('OTP resend failed. Please try again.');
      }
    } catch (error) {
      setError('Failed to resend OTP. Please try again.');
    }
  };

  const handleVerify = async (event) => {
    event.preventDefault();
    const otpverify = sessionStorage.getItem(`OTP${sessionId}`);

    try {
      if (otpverify === otp) {
        const response = await axios.post('http://18.61.175.229:3000/register', formDataval);
        if (response.data.status === true) {
          window.location.href = 'https://khatatracker.com/business/login';
        }

      } else {
        setError('Please provide valid OTP');
      }
    } catch (error) {
      setError('An error occurred. Please try again.');
    }
  };

  return (
    <div>

      {/* <h3 className="modal-title">OTP Verification</h3>
      <p>OTP sent to your email: {email}</p>

      <form onSubmit={handleVerify}>
        <div style={{ display: 'flex', justifyContent: 'center', marginBottom: '20px' }}>
          <input
            type="text"
            value={otp}
            onChange={(e) => handleInputChange(e.target.value)}
            maxLength="6"
            placeholder="Enter OTP"
            style={{
              textAlign: 'center',
              width: '200px',
              height: '40px',
              fontSize: '20px',
              margin: '5px',
              border: '1px solid #ccc',
              borderRadius: '5px',
            }}
          />
        </div>

    <button type="submit" className="">
          Verify OTP
        </button>
        {error && <p style={{ color: 'red', textAlign: 'center', marginTop: '10px' }}>{error}</p>}
      </form>
      <button onClick={handleResend} style={{ marginTop: '10px', color: 'blue', textAlign: 'center' }}>
        Resend OTP
      </button>  */}
      <Form onSubmit={handleVerify}>
        
        <Form.Group>
          <Form.Label>Enter Verification Code</Form.Label>
          <Form.Control
            type="text"
            value={otp}
            maxLength="6"
            placeholder="Enter 6 digit Verification Code"
            onChange={(e) => handleInputChange(e.target.value)}
            autoComplete="off"
            required
          />
        </Form.Group>
        {error && <p style={{ color: 'red', textAlign: 'center', marginTop: '10px', fontSize: '1em' }}>{error}</p>}
        <div className="d-flex justify-content-center mt-3">
          <Button type="submit" style={{ background: '#086351', display: 'flex' }}>
            Verify
          </Button>
        </div>
      </Form>
    </div >
  );
};

export default OTPVerification;
