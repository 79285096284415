import React from 'react';
import './style.css'
const About = () => {
  return (
    <section className="section-padding" id="about_page">
      <div className="container">
        <div className="text-center">
          <div className="col-xs-12 col-md-10 col-md-offset-1">
            <div className="page-title mass">
              <div className="space-20"></div>
              <h5 className="title">ABOUT KHATA TRACKER</h5>
              <div className="space-30"></div> 
              <p >
             <b> Khata Tracker</b> for Business Accounts is your all-in-one solution for simplified financial management, making it easy to handle ledgers, transactions, and expenses. Whether managing cash, credit, loans, or invoices, this app streamlines every aspect of the financial process. Effortlessly track bills, record income and expenses, and maintain control over your budget. Designed for small businesses and entrepreneurs, Khata Tracker keeps your accounts organized with detailed insights and reports. Manage your business finances confidently with this powerful ledger and expense management tool.
             </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default About;
