import React from 'react';
import download from '../assets/images/download-image.png';
import play from '../assets/images/PlayStoreBadge.png';
import google from '../assets/images/GooglePlayStore.png';
const Download = () => {
  
  return (
    
    <div >
      <section  id="download_page">
      <div className="download-area overlay" >
        <div className="container">
          <div className="row">
            <div className="col-xs-12 col-sm-6 hidden-sm">
              <figure className="mobile-image">
                <img src={download} alt="Download the Khata Tracker App" />
              </figure>
            </div>
            <div className="col-xs-12 col-md-6 section-padding">
              <h3 className="white-color"><b>Download the Khata Tracker App</b></h3>
              <h5 className="blinking-text">
  <b>
    <span style={{  padding: '10px', color: 'yellow', backgroundColor: 'green',borderStartStartRadius:'25px' , borderEndEndRadius: '25px' }}>
    Stay on Top of Your Finances with Khata Tracker !</span></b>
</h5>

{/* <h5>
      <b>
        <span className="blinking-text" style={{ padding: '10px', backgroundColor: 'green', borderRadius: '25px' }}>
          Stay on Top of Your Finances with Khata Tracker!
        </span>
      </b>
    </h5> */}
    
 <div className="space-20"></div>
              <p>Khata Tracker makes it easy to manage all your customer transactions in one 
place. Instantly view detailed transaction histories and send professional reports 
to your customers for their reference. Take control of your business finances 
effortlessly with Khata Tracker.</p>
              <div className="space-60"></div>
              <a href="https://apps.apple.com/in/app/khata-book-my-spending-tracker/id1517793704" target="_blank" rel="noopener noreferrer">
                <img src={play} alt="Download on the App Store" style={{marginleft:"10px"}}/>
              </a>
              <a href="https://play.google.com/store/apps/details?id=personal.expense.budget.pocket.moneytracker" target="_blank" rel="noopener noreferrer">
                <img src={google} alt="Get it on Google Play" />
              </a>
            </div>
          </div>
        </div>
      </div>
      </section>
    </div>
  );
};

export default Download;

